<template>
    <section class="cards-container">
        <h4>Relatório de guias</h4>
        <div v-if="resume" class="cards">
            <SimpleCard
                :isMoney="true"
                cursor="pointer"
                title="Valor faturado"
                :value="resume.items_total"
                color="#0C1D59"
            />
            <SimpleCard
                :isMoney="false"
                cursor="pointer"
                title="Qtde. de guias"
                :value="resume.guides_count"
                color="#304388"
            />
            <SimpleCard
                :isMoney="true"
                cursor="pointer"
                title="Valor recebido"
                :value="resume.items_paid"
                color="#069763"
            />
            <SimpleCard
                :isMoney="true"
                cursor="pointer"
                title="Valor glosado"
                :value="resume.items_glossed"
                color="#F63220"
            />
            <SimpleCard
                :isMoney="true"
                cursor="pointer"
                title="Valor em aberto"
                :value="resume.items_opened"
                color="#FF6B00"
            />
            <SimpleCard
                :isMoney="true"
                cursor="pointer"
                title="Valor recuperado"
                :value="resume.items_recovered"
                color="#305BF2"
            />
        </div>
        <div v-else class="cards">
            <div class="skeleton"><b-skeleton animation="fade" width="100%" heigth="40px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="100%" heigth="40px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="100%" heigth="40px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="100%" heigth="40px"></b-skeleton></div>
            <div class="skeleton"><b-skeleton animation="fade" width="100%" heigth="40px"></b-skeleton></div>
        </div>
    </section>
</template>
<script>
    export default {
        components: {
            SimpleCard: () => import('@/components/Dashboard/SimpleCard'),
        },
        props: {
            resume: Object,
        },  
    }
</script>
<style lang="scss" scoped>
    .cards-container {
        h4 {
            color: #0C1D59; 
            font-weight: 600;
            font-size: 18px;
        }
        .cards {
            width: 100%;
            gap: 10px;
            display: inline-flex;
            justify-content: space-between;
        }
    }
    .skeleton {
        width: 200px;
        height: 100px;
    }
</style>